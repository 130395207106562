import { Layout } from '@explo-tech/react-grid-layout';
import cx from 'classnames';
import { FC, useContext, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { sendPing } from 'actions/pingActions';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { AddChartMenu } from 'components/EditableSection/AddChartMenu';
import * as styles from 'components/EditableSection/index.css';
import { sprinkles } from 'components/ds';
import { EmbedButton, EmbedPopover } from 'components/embed';
import { PingTypes } from 'constants/pingTypes';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { setIsEditingEditableSection } from 'reducers/dashboardInteractionsReducer';
import { AllStates } from 'reducers/rootReducer';
import { getEmbeddedDashboardWithDrilldowns } from 'reducers/selectors';
import {
  discardEditableSectionLayoutChangesThunk,
  saveEditableSectionThunk,
} from 'reducers/thunks/editableSectionThunks';
import * as RD from 'remotedata';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import {
  EDSInteraction,
  pingEDSInteractionMsg,
  trackEDSInteractionEvent,
} from 'utils/edsPingUtils';

type Props = {
  cols: number;
  chartsToAdd: EditableSectionChart[];
  isEditing: boolean;
  isEditingDashboard: boolean;
  layout: Layout[] | undefined;
  variables: DashboardVariableMap | null;
};

export const EditableSectionHeader: FC<Props> = ({
  isEditing,
  isEditingDashboard,
  chartsToAdd,
  cols,
  layout,
  variables,
}) => {
  const dispatch = useDispatch();
  const context = useContext(DashboardLayoutContext);

  const { hasEditableSectionChanges, isEmbed, customer, embedDashboard, team } = useSelector(
    (state: AllStates) => ({
      // Only true in embedded
      hasEditableSectionChanges:
        'embedDashboard' in state ? state.embedDashboard.hasEditableSectionChanges : false,
      isEmbed:
        'dashboardLayout' in state ? state.dashboardLayout.requestInfo.type === 'embedded' : false,
      customer: 'embedDashboard' in state ? state.embedDashboard.customer : undefined,
      embedDashboard:
        'embedDashboard' in state ? getEmbeddedDashboardWithDrilldowns(state) : undefined,
      team: 'embedDashboard' in state ? state.embedDashboard.team : undefined,
    }),
    shallowEqual,
  );

  const [isChartMenuOpen, setIsChartMenuOpen] = useState(false);

  const disableAddCharts = chartsToAdd.length === 0;

  const maybeSendPing = (interaction: EDSInteraction) => {
    if (!RD.isSuccess(embedDashboard)) {
      return;
    }
    dispatch(
      sendPing({
        postData: {
          message: pingEDSInteractionMsg({
            customer: customer ?? undefined,
            team: team ?? undefined,
            dashboard: embedDashboard.data,
            interaction,
          }),
          message_type: PingTypes.PING_END_USER_ACTION,
        },
      }),
    );
    trackEDSInteractionEvent({
      customer: customer ?? undefined,
      team: team ?? undefined,
      dashboard: embedDashboard.data,
      interaction,
    });
  };

  const onClickEdit = () => {
    dispatch(setIsEditingEditableSection(true));
    maybeSendPing(EDSInteraction.EDIT);
  };

  const onClickDiscard = () => {
    dispatch(discardEditableSectionLayoutChangesThunk());
    maybeSendPing(EDSInteraction.DISCARDED);
  };

  const onClickSave = () => {
    dispatch(saveEditableSectionThunk());
    maybeSendPing(EDSInteraction.SAVED);
  };

  return isEditing ? (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
      <EmbedPopover
        align="end"
        className={cx(
          styles.addChartMenu,
          embedSprinkles({ backgroundColor: 'containerFill', body: 'primary' }),
          GLOBAL_STYLE_CLASSNAMES.container.outline.border,
          GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
        )}
        isOpen={isChartMenuOpen}
        onOpenChange={(isOpen) => setIsChartMenuOpen(isOpen && !disableAddCharts)}
        portalContainerId={context.dashboardLayoutTagId}
        trigger={
          <EmbedButton
            disabled={disableAddCharts}
            icon="plus"
            tooltipProps={{
              text:
                isEditing || isEmbed
                  ? 'Add a chart to this section'
                  : 'To toggle charts from the default layout, use the config panel on the left.',
            }}
            variant="primary">
            Add Chart
          </EmbedButton>
        }
        width="large">
        <AddChartMenu
          chartsToAdd={chartsToAdd}
          isEditing={isEditing}
          layout={layout}
          numCols={cols}
          onItemClicked={() => setIsChartMenuOpen(false)}
          variables={variables}
        />
      </EmbedPopover>
      {/* Never visible in dashboard because hasEditableSectionChanges will be false */}
      {hasEditableSectionChanges ? (
        <EmbedButton
          icon="trashUndo"
          onClick={onClickDiscard}
          tooltipProps={{ text: 'Discard all changes to this section' }}
          variant="primary"
        />
      ) : null}
      <EmbedButton
        icon="check"
        onClick={onClickSave}
        tooltipProps={{
          text: hasEditableSectionChanges ? 'Save changes' : 'Done editing',
        }}
        variant="primary">
        {hasEditableSectionChanges ? 'Save' : 'Done'}
      </EmbedButton>
    </div>
  ) : (
    <EmbedButton
      disabled={isEditingDashboard}
      onClick={onClickEdit}
      tooltipProps={{
        text:
          isEditing || isEmbed
            ? 'Move, add, and delete charts in this section'
            : 'To edit the default layout, use the config panel on the left.',
      }}
      variant="primary">
      Edit
    </EmbedButton>
  );
};
