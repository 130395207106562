import { Layout } from '@explo-tech/react-grid-layout';
import { PayloadAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  createChildDashboardError,
  createChildDashboardRequest,
  createChildDashboardSuccess,
} from 'actions/dashboardActions';
import { createDashboardDataPanel, createDashboardElement } from 'actions/dashboardV2Actions';
import { OPERATION_TYPES } from 'constants/types';
import { DASHBOARD_ELEMENT_TYPES, VIEW_MODE } from 'types/dashboardTypes';

import {
  addEditableSectionChart,
  duplicateEditableSectionChart,
} from './dashboardEditConfigReducer';
import { clearDashboardLayoutReducer } from './dashboardLayoutReducer';

export enum PanelTab {
  ADD = 'add',
  CONFIGURE = 'configure',
  VARIABLES = 'variables',
  DATASETS = 'datasets',
  LAYERS = 'layers',
}

type SelectedDashboardItem = {
  id: string;
  type: DASHBOARD_ELEMENT_TYPES;
};

type AddingDataPanelState = {
  layout: Layout[];
  containerId: string | undefined;
  opType: OPERATION_TYPES;
};

type EditingDataPanelState = {
  opType: OPERATION_TYPES;
  selectedDatasetId: string;
};

type DashboardLayoutInteractionsInfo = {
  isEditing: boolean;
  viewMode: VIEW_MODE;
  disableInputs?: boolean;
  disableFiltersWhileLoading?: boolean;
  supportEmail?: string;
  disableEditingEditableSection?: boolean;
  hideEditableSectionEditControls?: boolean;
  updateUrlParams?: boolean;
  shouldPersistCustomerState?: boolean;
};

export enum EditingLayout {
  STICKY_HEADER = 'STICKY_HEADER',
  EDITABLE_SECTION = 'EDITABLE_SECTION',
}

export interface SelectedDashboardDrilldownInfo {
  drilldownEntryPointId: string;
  sourceDataPanelId: string;
  selectedPrimaryField: string;
  selectedSecondaryField: string | undefined;
}

interface DashboardInteractionsReducer {
  interactionsInfo: DashboardLayoutInteractionsInfo;
  selectedItem: SelectedDashboardItem | null;
  isEditingEditableSection: boolean;

  // All below used exclusively for explore interactions
  editingLayout: EditingLayout | null;
  addDataPanelState: AddingDataPanelState | null;
  editDataPanelState: EditingDataPanelState | null;
  deleteSelectedItemModalOpen: boolean;
  draggingElementType: string | null;
  paneOpenStates: { left: boolean; right: boolean };
  panelTab: PanelTab;
  // Used for showing hover states with link filters
  highlightedDataPanelId: string | null;
  // Used to control whether dataset editor is open
  selectedDatasetId: string | null;
  // Used to control which tab is open by default in DS editor
  isDrilldownPreview: boolean;
  // Used to size dataset editor
  isSidebarOpen: boolean;
  isCreatingChildDashboard: boolean;
  selectedDashboardDrilldownInfo: SelectedDashboardDrilldownInfo | null;
}

const getInitialState = (): DashboardInteractionsReducer => ({
  interactionsInfo: { isEditing: false, viewMode: VIEW_MODE.DEFAULT },
  selectedItem: null,
  isEditingEditableSection: false,
  editingLayout: null,
  addDataPanelState: null,
  editDataPanelState: null,
  deleteSelectedItemModalOpen: false,
  draggingElementType: null,
  paneOpenStates: { left: true, right: false },
  highlightedDataPanelId: null,
  panelTab: PanelTab.ADD,
  selectedDatasetId: null,
  isDrilldownPreview: false,
  isSidebarOpen: true,
  isCreatingChildDashboard: false,
  selectedDashboardDrilldownInfo: null,
});

const dashboardInteractionsSlice = createSlice({
  name: 'dashboardInteractions',
  initialState: getInitialState(),
  reducers: {
    setInteractionsInfo: (state, { payload }: PayloadAction<DashboardLayoutInteractionsInfo>) => {
      if (state.interactionsInfo.isEditing !== payload.isEditing) resetEditState(state);
      state.interactionsInfo = payload;
    },
    setExploreInteractionsInfo: (
      state,
      { payload }: PayloadAction<Partial<DashboardLayoutInteractionsInfo>>,
    ) => {
      if (payload.isEditing !== undefined && state.interactionsInfo.isEditing !== payload.isEditing)
        resetEditState(state);

      if (payload.viewMode) state.interactionsInfo.viewMode = payload.viewMode;
      if (payload.isEditing !== undefined) state.interactionsInfo.isEditing = payload.isEditing;
      if (payload.disableFiltersWhileLoading !== undefined) {
        state.interactionsInfo.disableFiltersWhileLoading = payload.disableFiltersWhileLoading;
      }
      if (payload.supportEmail !== undefined)
        state.interactionsInfo.supportEmail = payload.supportEmail;
    },
    setSelectedDashboardItem: (
      state,
      { payload }: PayloadAction<{ id: string; type?: DASHBOARD_ELEMENT_TYPES }>,
    ) => {
      setSelectedItem(state, payload.id, payload.type, false);

      state.draggingElementType = null;
      state.deleteSelectedItemModalOpen = false;
    },
    clearSelectedDashboardItem: (state) => {
      state.selectedItem = null;
      state.panelTab = state.editingLayout ? PanelTab.CONFIGURE : PanelTab.ADD;
    },
    setEditingLayout: (state, { payload }: PayloadAction<EditingLayout | null>) => {
      state.editingLayout = payload;
      state.selectedItem = null;
      state.panelTab = payload ? PanelTab.CONFIGURE : PanelTab.ADD;
    },
    setAddDataPanelState: (state, { payload }: PayloadAction<AddingDataPanelState | null>) => {
      state.addDataPanelState = payload;
    },
    setEditDataPanelState: (state, { payload }: PayloadAction<EditingDataPanelState | null>) => {
      state.editDataPanelState = payload;
    },
    setDraggingElementType: (state, { payload }: PayloadAction<string | null>) => {
      state.draggingElementType = payload;
    },
    setHighlightedDataPanelId: (state, { payload }: PayloadAction<string | null>) => {
      state.highlightedDataPanelId = payload;
    },
    setDeleteSelectedItemModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.deleteSelectedItemModalOpen = payload;
    },
    setSelectedDatasetId: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedDatasetId = payload;
    },
    setIsDrillDownPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrilldownPreview = payload;
    },
    setIsSidebarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSidebarOpen = payload;
    },
    toggleVisiblePanes: (state, { payload }: PayloadAction<string[]>) => {
      state.paneOpenStates.right = payload.some((pane) => pane === 'right');
      state.paneOpenStates.left = payload.some((pane) => pane === 'left');
    },
    setIsEditingEditableSection: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditingEditableSection = payload;
    },
    setPanelTab: (state, { payload }: PayloadAction<PanelTab>) => {
      state.panelTab = payload;
      if (state.editingLayout) state.selectedItem = null;
    },
    setSelectedDashboardDrilldownInfo: (
      state,
      { payload }: PayloadAction<SelectedDashboardDrilldownInfo | null>,
    ) => {
      state.selectedDashboardDrilldownInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearDashboardLayoutReducer, () => {
        return getInitialState();
      })
      .addCase(createDashboardElement, (state, { payload }) => {
        setSelectedItem(state, payload.id, payload.elementType);
      })
      .addCase(createDashboardDataPanel, (state, { payload }) => {
        setSelectedItem(state, payload.id);
      })
      .addCase(addEditableSectionChart, (state, { payload }) => {
        setSelectedItem(state, payload.id, undefined, false);
      })
      .addCase(duplicateEditableSectionChart, (state, { payload }) => {
        setSelectedItem(state, payload.newId, undefined, false);
      })
      .addCase(createChildDashboardRequest, (state) => {
        state.isCreatingChildDashboard = true;
      })
      .addMatcher(isAnyOf(createChildDashboardSuccess, createChildDashboardError), (state) => {
        state.isCreatingChildDashboard = false;
      });
  },
});

export const {
  setInteractionsInfo,
  setSelectedDashboardItem,
  setEditingLayout,
  setAddDataPanelState,
  setDraggingElementType,
  setExploreInteractionsInfo,
  setHighlightedDataPanelId,
  setDeleteSelectedItemModalOpen,
  toggleVisiblePanes,
  setIsEditingEditableSection,
  clearSelectedDashboardItem,
  setEditDataPanelState,
  setPanelTab,
  setSelectedDatasetId,
  setIsDrillDownPreview,
  setIsSidebarOpen,
  setSelectedDashboardDrilldownInfo,
} = dashboardInteractionsSlice.actions;

export const dashboardInteractionsReducer = dashboardInteractionsSlice.reducer;

const resetEditState = (state: DashboardInteractionsReducer) => {
  state.selectedItem = null;
  state.editingLayout = null;
  state.addDataPanelState = null;
  state.draggingElementType = null;
  state.highlightedDataPanelId = null;
  state.panelTab = PanelTab.ADD;
};

const setSelectedItem = (
  state: DashboardInteractionsReducer,
  itemId: string,
  type?: DASHBOARD_ELEMENT_TYPES,
  resetEditingLayout = true,
) => {
  state.selectedItem = { id: itemId, type: type ?? DASHBOARD_ELEMENT_TYPES.DATA_PANEL };
  state.draggingElementType = null;
  state.panelTab = PanelTab.CONFIGURE;
  if (resetEditingLayout) state.editingLayout = null;
};

export const getIsFullPageDashboard = createSelector(
  (state: DashboardInteractionsReducer) => state.interactionsInfo,
  ({ isEditing, viewMode }) =>
    !isEditing ||
    viewMode === VIEW_MODE.PDF ||
    viewMode === VIEW_MODE.EMAIL ||
    viewMode === VIEW_MODE.MOBILE,
);
