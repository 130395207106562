import { Layout } from '@explo-tech/react-grid-layout';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cx from 'classnames';
import { Settings } from 'luxon';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { sendPing } from 'actions/pingActions';
import { ArchetypeProperty, Customer, EmbedCustomer } from 'actions/teamActions';
import { sprinkles } from 'components/ds';
import { DEFAULT_EMAIL_WIDTH_PX, Timezones } from 'constants/dashboardConstants';
import { DASHBOARD_LOADED_CLASS_NAME } from 'constants/exportConstants';
import { EXPLO_SVG_LOGO } from 'constants/iconConstants';
import { PingTypes } from 'constants/pingTypes';
import { GlobalStylesContext } from 'globalStyles';
import { DashboardDrilldownsBanner } from 'pages/dashboardPage/DashboardDrilldownsBanner';
import { ViewUnderlyingDataModal } from 'pages/dashboardPage/charts/ViewUnderlyingDataModal';
import { setDashboardVariables, updateDashboardVariables } from 'reducers/dashboardDataReducer';
import {
  clearDashboardLayoutReducer,
  DashboardLayoutRequestInfo,
  getIsIframe,
  setRequestInfo,
} from 'reducers/dashboardLayoutReducer';
import {
  clearDrilldownsState,
  DrilldownDatasetFilter,
  DrilldownSourceInfo,
  setCurrentSourceInfos,
  setDrilldownFilters,
} from 'reducers/drilldownsReducer';
import { DashboardStates } from 'reducers/rootReducer';
import {
  sendDashboardLoadedEventThunk,
  sendDashboardUpdatedEventThunk,
} from 'reducers/thunks/customEventThunks';
import { fetchDatasetsThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import {
  fetchDashboardDataThunk,
  initializeDashboardDataThunk,
  onNewDataPanelsAddedThunk,
} from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { handleCustomerChangeThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { clearSelectedItemThunk } from 'reducers/thunks/dashboardSelectionThunks';
import {
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  PAGE_TYPE,
  VIEW_MODE,
} from 'types/dashboardTypes';
import {
  DashboardPageLayoutConfig,
  DashboardParam,
  EditableSectionConfig,
} from 'types/dashboardVersionConfig';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getCustomerVariables } from 'utils/customerUtils';
import * as dashboardUtils from 'utils/dashboardUtils';
import { createDebouncedFn } from 'utils/general';
import { getDefaultVariablesFromParams } from 'utils/paramUtils';
import { isEqual } from 'utils/standard';
import * as variableUtils from 'utils/variableUtils';

import { DashboardBody } from './DashboardBody';
import DashboardLayoutContext from './DashboardLayoutContext';
import { DashboardLayoutPoller } from './DashboardLayoutPoller';
import { DashboardStickyHeader } from './DashboardStickyHeader';
import * as styles from './styles.css';

const MINIMUM_MINUTES_FOR_REFRESH = 0.25; // 15 Seconds

const debounceFn = createDebouncedFn(500);

type PassedProps = {
  archetypeProperties: ArchetypeProperty[] | undefined;
  datasets: Record<string, ResourceDataset>;
  dashboardElements: DashboardElement[];
  dashboardLayout: Layout[];
  dataPanels: DataPanel[];
  params?: Record<string, DashboardParam>;
  resourceId: number;
  isEditableSectionEnabled?: boolean;
  isViewOnly: boolean;
  isVisible?: boolean;
  pageLayoutConfig?: DashboardPageLayoutConfig;
  pageType: PAGE_TYPE;
  refreshMinutes?: number;
  requestInfo: DashboardLayoutRequestInfo;
  showExploBranding?: boolean;
  teamName: string | undefined;
  customer: Customer | EmbedCustomer | undefined;
  dashboardId?: string; // For customers to identify embed dashboards (if multiple on the same page)
  variablesDefaultValues?: DashboardVariableMap;
  width?: number | null;
  height?: number | null;
  editableSectionConfig?: EditableSectionConfig;
  drilldownVariables: Record<string, DashboardVariable>;
  drilldownSourceInfos: DrilldownSourceInfo[];
  drilldownDatasetFilters: Record<string, DrilldownDatasetFilter>;
  rootDashboardElements: DashboardElement[];
};

type Props = PassedProps & PropsFromRedux;

type State = {
  intervalId?: number;
  /*
   * When switching quickly between dashboards constructor of next dashboard
   * is called before componentWillUnmount of initial dashboard. Keeping this
   * status allows us to make sure that the initial request is made after first
   * component has unmounted and next one has set necessary information
   */
  hasInitialRequestBeenMade: boolean;
  height: number;
};

class DashboardLayout extends Component<Props, State> {
  state: State = {
    hasInitialRequestBeenMade: false,
    height: 0,
  };

  constructor(props: Props) {
    super(props);

    // the luxon default timezone is local, set this to UTC just so everything's
    // standard. In reality, dates are handled by the backend so this shouldn't
    // matter
    Settings.defaultZone = Timezones.UTC;
  }

  componentDidMount() {
    const {
      dashboardElements,
      dataPanels,
      variablesDefaultValues,
      customer,
      setDashboardVariables,
      refreshMinutes,
      requestInfo,
      params,
      archetypeProperties,
      editableSectionConfig,
      isEditableSectionEnabled,
      drilldownVariables,
      setCurrentSourceInfos,
      setDrilldownFilters,
    } = this.props;

    this.props.setRequestInfo(requestInfo);

    let elemDefaultVars = variableUtils.getDefaultVariablesFromDashElements(
      dashboardElements,
      requestInfo.timezone,
      variablesDefaultValues,
    );

    const archetypePropertySet = new Set(archetypeProperties?.map((prop) => prop.name));

    elemDefaultVars = {
      ...(params ? getDefaultVariablesFromParams(params) : undefined),
      ...elemDefaultVars,
      ...variablesDefaultValues,
      ...variableUtils.initializeDpFilterVariables(dataPanels),
      ...(customer && editableSectionConfig && isEditableSectionEnabled
        ? variableUtils.initializeEditableSectionFilterVariables(editableSectionConfig, customer)
        : undefined),
      ...(customer ? getCustomerVariables(customer, archetypePropertySet) : undefined),
      ...drilldownVariables,
    };

    setDashboardVariables(elemDefaultVars);
    setCurrentSourceInfos(this.props.drilldownSourceInfos);
    setDrilldownFilters(this.props.drilldownDatasetFilters);

    if (refreshMinutes && refreshMinutes >= MINIMUM_MINUTES_FOR_REFRESH) {
      this.setState({
        intervalId: window.setInterval(
          this.refreshDashboard.bind(this),
          // convert minutes to milliseconds
          refreshMinutes * 60 * 1000,
        ),
      });
    }

    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.requestInfo !== this.props.requestInfo) {
      this.props.setRequestInfo(this.props.requestInfo);
    }

    if (!this.state.hasInitialRequestBeenMade) {
      if (this.props.variables === null) return;
      this.props.initializeDashboardDataThunk(this.props.dashboardElements, this.props.datasets);
      this.setState({ hasInitialRequestBeenMade: true });
      return;
    }

    const versionNumberChanged =
      prevProps.requestInfo.versionNumber !== this.props.requestInfo.versionNumber;
    const timezoneChanged = prevProps.requestInfo.timezone !== this.props.requestInfo.timezone;
    const customerChanged = prevProps.customer?.id !== this.props.customer?.id;
    const useFidoChanged = prevProps.requestInfo.useFido !== this.props.requestInfo.useFido;

    if (versionNumberChanged || timezoneChanged || customerChanged || useFidoChanged) {
      if (customerChanged && this.props.customer) {
        this.props.handleCustomerChangeThunk(
          this.props.dashboardElements,
          this.props.datasets,
          this.props.customer,
        );
      } else {
        this.props.initializeDashboardDataThunk(this.props.dashboardElements, this.props.datasets);
      }
      return;
    }

    if (
      prevProps.isVisible !== this.props.isVisible &&
      this.props.refreshMinutes &&
      this.props.refreshMinutes >= MINIMUM_MINUTES_FOR_REFRESH
    ) {
      if (this.props.isVisible) {
        // if the tab is refocused on, re-set the refresh interval
        this.setState({
          intervalId: window.setInterval(
            this.refreshDashboard.bind(this),
            // convert minutes to milliseconds
            this.props.refreshMinutes * 60 * 1000,
          ),
        });
      } else {
        // stop fetching dashboard data if tab is closed to prevent
        // browser performance issues
        if (this.state.intervalId) clearInterval(this.state.intervalId);
      }
    }

    if (
      this.props.variablesDefaultValues &&
      this.props.variablesDefaultValues !== prevProps.variablesDefaultValues &&
      !isEqual(prevProps.variablesDefaultValues, this.props.variablesDefaultValues)
    ) {
      this.props.updateDashboardVariables(this.props.variablesDefaultValues);
    }

    if (prevProps.width !== this.props.width) {
      // Trigger resize event for react-grid-layout if container size changes
      debounceFn(() => window.dispatchEvent(new Event('resize')));
    }

    const newDataPanels = dashboardUtils.dataPanelsAdded(
      prevProps.dataPanels,
      this.props.dataPanels,
    );

    if (newDataPanels.length) this.props.onNewDataPanelsAddedThunk(newDataPanels);

    const newElems = dashboardUtils.dashboardElementsAdded(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );
    if (newElems.length) {
      const defaultElemValues: DashboardVariableMap = {};
      newElems.forEach((newElem) => {
        const defaultValueForElem = dashboardUtils.getDefaultValueForNewElem(newElem);
        if (!defaultValueForElem) return;
        defaultElemValues[newElem.name] = defaultValueForElem;
      });

      this.props.updateDashboardVariables(defaultElemValues);
    }

    const newDatasetIds = dashboardUtils.datasetsChanged(
      prevProps.dashboardElements,
      this.props.dashboardElements,
    );
    if (newDatasetIds.length) this.props.fetchDatasetsThunk(newDatasetIds, this.props.datasets);

    const dashboardId = this.props.dashboardId || '';
    const height = document.body.offsetHeight;
    if (!prevProps.dashboardLoaded && this.props.dashboardLoaded) {
      window.DD_RUM?.addTiming('dashboard_loaded');
      this.props.sendDashboardLoadedEventThunk(dashboardId, height);
    } else if (this.props.dashboardLoaded && height > 0 && height !== this.state.height) {
      this.props.sendDashboardUpdatedEventThunk(dashboardId, height);
      this.setState({ height });
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    document.removeEventListener('keydown', this.handleKeyDown);

    if (intervalId) clearInterval(intervalId);
    this.props.clearDashboardLayoutReducer();
    this.props.clearDrilldownsState();
  }

  refreshDashboard = () => this.props.fetchDashboardDataThunk({});

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key !== 'Escape') return;
    this.props.clearSelectedItemThunk();
  };

  render() {
    const {
      datasets,
      dashboardElements,
      resourceId,
      dataPanels,
      dashboardLayout,
      isViewOnly,
      pageLayoutConfig,
      pageType,
      customer,
      clearSelectedItemThunk,
      viewMode,
      variables,
      dashboardLayoutTagId,
      dashboardLoaded,
      isEditableSectionEnabled,
      emailWidthPx,
      rootDashboardElements,
    } = this.props;

    return (
      <DashboardLayoutContext.Provider value={{ dashboardLayoutTagId }}>
        <div
          className={cx(rootStyle, {
            [sprinkles({ overflowY: 'visible' })]: viewMode === VIEW_MODE.PDF && !isViewOnly,
            [sprinkles({ overflowY: 'auto' })]: viewMode !== VIEW_MODE.PDF || isViewOnly,
            [cx(sprinkles({ height: 'auto' }), styles.emailView)]: viewMode === VIEW_MODE.EMAIL,
            [sprinkles({ height: 'fill' })]: viewMode !== VIEW_MODE.EMAIL,
            [styles.mobileEditor]: viewMode === VIEW_MODE.MOBILE && !isViewOnly,
            [DASHBOARD_LOADED_CLASS_NAME]: dashboardLoaded,
          })}
          id={dashboardLayoutTagId}
          // Added to force a refresh and subsequent resizing of all charts when dashboard changes
          key={`${resourceId}${dashboardLayoutTagId}`}
          onClick={() => clearSelectedItemThunk()}
          style={{
            ...this.context.globalStyleVars,
            ...assignInlineVars({
              [styles.emailViewWidthVar]: `${emailWidthPx ?? DEFAULT_EMAIL_WIDTH_PX}px`,
            }),
          }}>
          {viewMode === VIEW_MODE.PDF || viewMode === VIEW_MODE.EMAIL ? null : (
            <DashboardStickyHeader
              config={pageLayoutConfig?.stickyHeader}
              dashboardElements={rootDashboardElements}
              datasets={datasets}
              variables={variables ?? {}}
              viewMode={viewMode}
            />
          )}
          <DashboardDrilldownsBanner globalStyleConfig={this.context.globalStyleConfig} />
          <DashboardBody
            customer={customer}
            dashboardElements={dashboardElements}
            dashboardLayout={dashboardLayout}
            dataPanels={dataPanels}
            datasets={datasets}
            globalStyleConfig={this.context.globalStyleConfig}
            isDemoCustomer={customer?.is_demo_group ?? false}
            isEditableSectionEnabled={isEditableSectionEnabled}
            isViewOnly={isViewOnly}
            pageType={pageType}
            variables={variables ?? {}}
            viewMode={viewMode}
          />
          {this.renderExploBranding()}
          <ViewUnderlyingDataModal
            dataPanels={dataPanels}
            datasets={datasets}
            pageType={pageType}
            variables={variables ?? {}}
          />
        </div>
        <DashboardLayoutPoller />
      </DashboardLayoutContext.Provider>
    );
  }

  renderExploBranding = () => {
    const { showExploBranding, sendPing, customer, teamName } = this.props;
    if (!showExploBranding) return null;

    return (
      <a
        className={exploBrandButton}
        href="https://www.explo.co/?utm_source=in-app-chip"
        onClick={() =>
          sendPing({
            postData: {
              message: `${customer?.name ?? 'Customer'} from ${
                teamName ?? 'Team'
              } clicked the Powered By Explo chip`,
              message_type: PingTypes.PING_POWERED_BY_EXPLO_CLICKED,
            },
          })
        }
        rel="noopener noreferrer"
        target="_blank">
        {EXPLO_SVG_LOGO(18)}
        <span className={sprinkles({ marginLeft: 'sp1' })}>Powered by Explo</span>
      </a>
    );
  };
}

DashboardLayout.contextType = GlobalStylesContext;

const mapStateToProps = (state: DashboardStates) => ({
  viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
  variables: state.dashboardData.variables,
  dashboardLayoutTagId: state.dashboardLayout.layoutId,
  dashboardLoaded: state.dashboardData.dashboardLoaded,
  isIframe: getIsIframe(state.dashboardLayout),
  emailWidthPx: state.dashboardLayout.requestInfo.emailWidthPx,
});

const mapDispatchToProps = {
  clearDashboardLayoutReducer,
  setRequestInfo,
  setDashboardVariables,
  initializeDashboardDataThunk,
  fetchDashboardDataThunk,
  handleCustomerChangeThunk,
  updateDashboardVariables,
  onNewDataPanelsAddedThunk,
  fetchDatasetsThunk,
  clearSelectedItemThunk,
  sendDashboardLoadedEventThunk,
  sendDashboardUpdatedEventThunk,
  sendPing,
  clearDrilldownsState,
  setCurrentSourceInfos,
  setDrilldownFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

// Using this allows correct typing of thunk props
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DashboardLayout);

const rootStyle = sprinkles({
  width: 'fill',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const exploBrandButton = sprinkles({
  backgroundColor: { default: 'white', hover: 'gray1' },
  borderRadius: 8,
  bottom: 'sp1.5',
  color: 'gray12',
  flexItems: 'alignCenter',
  fontWeight: 500,
  height: 32,
  paddingX: 'sp1.5',
  position: 'absolute',
  right: 'sp1.5',
  textDecoration: { hover: 'none' },
});
